import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { capitalize, formatPrice } from "../../utils/FrontEndHelpers";
import PaypalComponent from "../../components/PaypalComponent";
import MoneyIcon from "@material-ui/icons/AttachMoney";
import ParkIcon from "@material-ui/icons/LocalParking";
import PaymentWalletComponent from "../../components/PaymentWalletComponent";

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    width: "280px",
  },
  mapDiv: {
    height: "160px",
    borderRadius: "15px",
  },
  locationNameDiv: {
    fontSize: "20px",
    textAlign: "center",
    marginTop: "15px",
    fontWeight: "bold",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "10px",
    alignItems: "center",
    boxShadow: "0 8px 24px rgb(149 157 165 / 20%)",
    width: 130,
    height: 130,
  },
  cardsDiv: {
    display: "flex",
    justifyContent: "space-between",
  },
  paymentButtonDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    padding: "10px 0",
    margin: "10px 0",
  },
  subTitle: {
    fontSize: "12px",
    color: "gray",
  },
  iconDiv: {
    margin: "20px 0",
    backgroundColor: "#000",
    height: 28,
    width: 40,
    borderRadius: 6,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const SessionReview = ({
  handleBack,
  handleNext,
  sessionData,
  setSession,
  zoneObj,
}) => {
  const classes = useStyles();
  const [center, setCenter] = useState("");
  const [paymentError, setPaymentError] = useState("");
  const [formattedCurrency, setFormattedCurrency] = useState("");

  const setUpMap = () => {
    const {
      location: {
        location_point: { coordinates },
      },
    } = zoneObj;
    setCenter(coordinates);
  };

  const formatCurrency = () => {
    const hourlyCurrency =
      sessionData.hours * sessionData.rate + sessionData.fees;
    const dailyCurrency = sessionData.rate + sessionData.fees;
    // const currencyNumber = new Intl.NumberFormat("en-IN", {
    //   style: "currency",
    //   currency: "USD",
    //   minimumFractionDigits: 2,
    // }).format(hourlyCurrency);

    // TODO: fix this
    const currc = sessionData.isHourly ? hourlyCurrency : dailyCurrency;

    // console.log("line 91", currc);
    // console.log("line 92", sessionData.startTime);
    // console.log("line 93", hourlyCurrency);
    // console.log("line 94", currencyNumber);
    setFormattedCurrency(currc);
  };

  useEffect(() => {
    // console.log(sessionData);
    formatCurrency();
    setUpMap();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.mainDiv}>
      <div className={classes.mapDiv}>
        <img
          className="img-fluid"
          src={`https://maps.googleapis.com/maps/api/staticmap?size=280x160&scale=1&format=png&zoom=15&&maptype=roadmap&markers=size:normal%7Ccolor:red%7Clabel:P%7C${center[1]},${center[0]}&key=AIzaSyArO8E0ZBqSeZo-cKr-zP_LDs801m68a_o`}
          alt="map"
        />
      </div>
      <div style={{ marginBottom: "17px" }}>
        <div className={classes.locationNameDiv}>
          {capitalize(sessionData.locationName)}
        </div>
        <div style={{ textAlign: "center", fontSize: "10px", color: "gray" }}>
          {sessionData.locationAddress}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          <div>Zone: {sessionData.zoneNumber}</div>
          &nbsp;
          <div> - </div>
          &nbsp;
          <div>
            ${sessionData.rate} / {sessionData.isHourly ? "Hourly" : "Daily"}
          </div>
        </div>
      </div>
      <div className={classes.cardsDiv}>
        <div className={classes.card}>
          <div className={classes.iconDiv}>
            <ParkIcon style={{ color: "#fff", fontSize: "medium" }} />
          </div>
          <div style={{ fontWeight: "bold" }}>
            {sessionData.isHourly ? `${sessionData.hours} hour(s)` : `1 Day`}
          </div>
          <div className={classes.subTitle}>Duration</div>
        </div>
        <div className={classes.card}>
          <div className={classes.iconDiv}>
            <MoneyIcon style={{ color: "#fff", fontSize: "19px" }} />
          </div>
          <div style={{ fontWeight: "bold" }}>
            {formatPrice(formattedCurrency)}
          </div>
          <div className={classes.subTitle}>Est. Total</div>
        </div>
      </div>
      <div className={classes.paymentButtonDiv}>
        <PaymentWalletComponent
          handleNext={handleNext}
          setSession={setSession}
          sessionData={sessionData}
          setPaymentError={setPaymentError}
          zoneObj={zoneObj}
        />
        <PaypalComponent
          handleNext={handleNext}
          setSession={setSession}
          setPaymentError={setPaymentError}
          sessionData={sessionData}
          zoneObj={zoneObj}
        />
      </div>
      <div>
        {paymentError && (
          <div style={{ textAlign: "center", fontSize: "12px", color: "red" }}>
            {paymentError}
          </div>
        )}
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <BackButton onClick={handleBack}>Back</BackButton>
      </div>
    </div>
  );
};

const BackButton = styled.button`
  height: 50px;
  width: 280px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #27ae60;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: #2cc36b;
  }
`;

export default SessionReview;
