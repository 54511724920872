import React from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { Table } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "100%",
  },
  table: {
    minWidth: 650,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  tableheaders: {
    fontWeight: 900,
    color: "purple",
  },
}));

const RevenueTable = ({ revenues }) => {
  const classes = useStyles();
  return (
    <Table className={classes.root}>
      <TableContainer component={Paper}>
        <TableContainer className={classes.table} aria-label="simple table">
          <TableHead style={{ backgroundColor: "#fff" }}>
            <TableRow>
              <TableCell className={classes.tableheaders} align="left">
                <u>Date</u>
              </TableCell>
              <TableCell className={classes.tableheaders} align="left">
                <u>License Plate</u>
              </TableCell>
              <TableCell className={classes.tableheaders} align="left">
                <u>Rate</u>
              </TableCell>
              <TableCell className={classes.tableheaders} align="center">
                <u>Zone</u>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {revenues &&
              revenues.map((rev, idx) => (
                <TableRow hover key={idx} style={{ backgroundColor: "#fff" }}>
                  <TableCell align="center">
                    {moment(rev?.startTime).format("MM/DD/YYYY")}
                  </TableCell>
                  <TableCell align="center">
                    {rev.plateNumber} - {rev.state}
                  </TableCell>
                  <TableCell align="center">
                    ${rev.rate}
                    {rev.days === 0 ? "/hr" : "/day"}
                  </TableCell>
                  <TableCell align="center">#{rev.zoneNumber}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </TableContainer>
      </TableContainer>
    </Table>
  );
};

export default RevenueTable;
