import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import SessionCheckoutPage from "./SessionCheckoutPage";
import ZoneService from "../services/ZoneService";
import LocationIcon from "@material-ui/icons/LocationOn";
import CircleLoader from "../components/shared/CircleLoader";
import styled from "styled-components";

const ProxyCheckout = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [zone, setZone] = useState([]);
  const { locationId, zoneId } = useParams();

  const getZoneInfo = async () => {
    setIsLoading(true);

    const zoneInfo = {
      locationId,
      zoneId,
    };

    const zoneService = new ZoneService();
    const {
      data: { data },
    } = await zoneService.get(zoneInfo);

    setZone(data);
    setIsLoading(false);
  };

  useEffect(() => {
    getZoneInfo();
    // eslint-disable-next-line
  }, []);

  return (
    <div
      style={{ display: "flex", justifyContent: "center", maxWidth: "200px" }}
    >
      {isLoading ? (
        <CircleLoader />
      ) : zone?.zone?.archieved === true ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: 100,
          }}
        >
          <div style={{ fontSize: 30, fontWeight: 900 }}>
            <div>Zone: {zone?.zone?.zoneNumber}</div>
          </div>
          <div style={{ marginRight: 10 }}>
            <div
              style={{
                textTransform: "capitalize",
                fontWeight: 900,
                fontSize: 22,
                marginBotton: 6,
              }}
            >
              {zone.location.name}
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ marginRight: 10 }}>
                <LocationIcon fontSize="small" />
              </div>
              <div style={{ fontSize: 12 }}>{zone.location.address}</div>
            </div>
            <div
              style={{
                marginTop: 10,
                backgroundColor: "#f0f0f0",
                color: "#000",
                padding: 20,
                borderRadius: 10,
              }}
            >
              <p style={{ fontWeight: 900, fontSize: 20 }}>
                This zone is currently closed
              </p>
              <p>
                Parking is not allowed at this time here. Please check back
                later
              </p>
            </div>
          </div>

          <ContinueButton onClick={() => history.push("/locations")}>
            Find Another
          </ContinueButton>
        </div>
      ) : Object.keys(zone).length > 0 ? (
        <SessionCheckoutPage isLoading={isLoading} zoneObject={zone} />
      ) : (
        <div style={{ fontSize: 30, fontWeight: 900, textAlign: "center" }}>
          Not found
        </div>
      )}
    </div>
  );
};

export const ContinueButton = styled.button`
  height: 50px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #000;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: #454545;
  }
`;

export default ProxyCheckout;
